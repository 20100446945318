<template>
  <v-row justify="center" class="addCompanyPage">
    <v-dialog
    z-index="9999"
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5" v-if="!editView">Add new company</span>
          <span class="text-h5" v-if="editView">Update company</span>
        </v-card-title>
        <v-card-text style="max-height:450px; overflow-y:auto;">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-container>
              <strong>Basic details</strong>
              <v-row class="mt-2">
                <v-col cols="6" class="px-2 text-center">
                  <v-avatar
                    :tile="true" style="width:60%; height:130px;"
                    color="grey company-logo lighten-4"
                  >
                    <div class="file-upload-form" style="max-width: 100%;">
                      <input v-if="!imageData && !this.company.company_logo" type="file" @change="previewImage" accept="image/*">
                    </div>
                    <div class="image-preview df" v-if="imageData.length > 0">
                      <img alt="image" style="width:100%; margin-top:10px;" class="preview" :src="imageData">
                    </div>
                    <div class="image-preview" v-if="(!imageData || imageData.length === 0) && company.company_logo">
                      <img alt="image" style="width:100%; margin-top:10px;" class="preview" :src="company.company_logo">
                    </div>
                  </v-avatar><br>
                  <p class="cursor-pointer" v-if="imageData || company.company_logo" @click="changeLogo()">Change logo</p>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col class="py-0"
                      cols="12"
                    >
                    <label>Company name*</label>
                      <v-text-field
                        v-model="company.company_name"
                        name="company_name"
                        placeholder="Enter your company name"
                        outlined 
                        :rules="nameRules"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0"
                      cols="12"
                    >
                    <label>Brand name*</label>
                      <v-text-field
                        v-model="company.brand_name"
                        placeholder="Enter brand name" outlined
                        dense
                        :rules="nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="w-100 ma-0">
                <v-col class="py-0"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <label>Industry type*</label>
                  <v-autocomplete
                    v-model="company.company_type"
                    :items="industryType"
                    placeholder="Select industry type"
                    outlined dense :rules="nameRules"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col class="py-0" cols="12"
                  sm="6"
                  md="6">
                  <label>Go live date</label>
                  <v-menu
                    v-model="joinDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        outlined
                        required
                        dense
                        readonly
                        @input="changeFormat()"
                        name="Go live date"
                        placeholder="Go live date"
                        v-model="company.activatedAt"
                        @blur="date1 = parseDate(company.activatedAt)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="go_live_date " @input="joinDateMenu = false, changeFormat()">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="py-0" cols="12"
                  sm="6"
                  md="6">
                  <label>Employee strength*</label>
                  <v-text-field
                    v-model="company.employee_strength"
                    placeholder="Enter employee strength"
                    outlined dense
                    :rules="intRules"
                    maxlength="9"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="12"
                  sm="6"
                  md="6">
                  <label>Users booked</label>
                  <v-text-field
                    v-model="company.user_booked"
                    placeholder="Enter the number of users booked"
                    :rules="company.user_booked ? intRules : ''"
                    maxlength="9"
                    outlined dense
                  ></v-text-field>
                </v-col>
                <v-col class="py-0 totalArrField"
                cols="12"
                sm="12"
                md="12"
              >
              <label>Total Annual Recurring Revenue </label>
                <v-text-field 
                 prepend-inner-icon="mdi mdi-currency-inr"
                v-model="company.total_arr"
                :rules="company.total_arr ? intRules : ''"
                placeholder="Total  Annual Recurring Revenue in INR "
                outlined dense
              ></v-text-field>
              </v-col>
                <v-col class="py-0"
                  cols="12"
                >
                  <label>Address*</label>
                  <v-text-field v-model="company.company_address"
                    placeholder="Enter address" outlined
                    dense :rules="addressRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="py-0"
                  cols="12"
                >
                  <label>Country*</label>
                  <v-text-field v-model="company.country"
                    placeholder="Enter country" outlined
                    dense :rules="countryRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <label>Account status*</label>
                  <v-radio-group 
                    v-model="company.is_active"
                    row
                  >
                    <v-radio color="#0ab39c"
                      label="Active"
                      :value="true"
                    ></v-radio>
                    <v-radio color="#0ab39c"
                      label="Inactive"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <label>Account type*</label>
                  <v-radio-group 
                    v-model="plan.account_type"
                    row
                  >
                    <v-radio color="#0ab39c"
                      label="Paid"
                      value="paid"
                    ></v-radio>
                    <v-radio color="#0ab39c"
                      label="Pilot"
                      value="pilot"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <hr>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
          >
            <v-container>
              <strong>Social links</strong>
              <v-row class="mt-2">
                <v-col class="py-0"
                  cols="12"
                  sm="6"
                  md="6"
                >
                <label>Facebook url</label>
                  <v-text-field
                    :rules="company.facebook_link ? urlRules : ''"
                    v-model="company.facebook_link"
                    placeholder="Enter facebook url" 
                    outlined dense
                  ></v-text-field>
                </v-col>
                <v-col  class="py-0"
                  cols="12"
                  sm="6"
                  md="6">
                <label>Linkedin url</label>
                  <v-text-field
                    :rules="company.linkedin_link ? urlRules : ''"
                    v-model="company.linkedin_link"
                    placeholder="Enter linkedin url" 
                    outlined dense
                  ></v-text-field>
                </v-col>
                <v-col  class="py-0"
                  cols="12"
                  sm="6"
                  md="6">
                <label>Glassdoor url</label>
                  <v-text-field
                    :rules="company.glassdoor_link ? urlRules : ''"
                    v-model="company.glassdoor_link"
                    placeholder="Enter glassdoor url" 
                    outlined dense
                  ></v-text-field>
                </v-col>
                <v-col  class="py-0"
                  cols="12"
                  sm="6"
                  md="6">
                <label>jobStreet url</label>
                  <v-text-field
                    :rules="company.jobStreet_link ? urlRules : ''"
                    v-model="company.jobStreet_link"
                    placeholder="Enter jobStreet url" 
                    outlined dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <hr>
          <strong>Avatars</strong>
          <!-- eslint-disable -->
          <v-row class="w-100 ma-0">
            <v-col cols="12" v-if="company && company.companies_avatar">
              <v-row class="w-100 ma-0" v-if="!editView">
                <v-col cols="3" v-for="(a, i) in company.companies_avatar" :key="i" style="cursor:pointer;" v-if="a.is_active" @click="changeAvatar(a.id)">
                  <div v-if="a.id" class="avatarBox" :style="company.company_avatar == a.id ? `border-color:#0ab39c; border-width: 2px; position:relative;` : ''" >
                    <img alt="image" style="margin-top:10px; padding: 0px 30%; width:100%;" :src="a.avatar">
                    <v-icon v-if="company.company_avatar == a.id" class="mb-0" style="position: absolute; top: 0; right:5px; text-align: center; font-size:20px !important; color:#0ab39c;">mdi-check-all</v-icon>
                  </div>
                </v-col>
              </v-row>
              <v-row class="w-100 ma-0" v-if="editView">
                <v-col cols="3" v-for="(a, i) in company.companies_avatar" :key="i" style="cursor:pointer;" @click="changeAvatar(i)">
                  <div class="avatarBox" :style="company.company_avatar == i ? `border-color:#0ab39c; border-width: 2px; position:relative;` : ''" >
                    <img alt="image" style="margin-top:10px; padding: 0px 30%; width:100%;" :src="a">
                    <v-icon v-if="company.company_avatar == i" class="mb-0" style="position: absolute; top: 0; right:5px; text-align: center; font-size:20px !important; color:#0ab39c;">mdi-check-all</v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
          >
          <v-container>
            <strong>SPOC details</strong>
            <v-row class="mt-2">
              <v-col class="py-0"
                cols="12"
                sm="6"
                md="6"
              >
              <label>SPOC name*</label>
                <v-text-field :rules="nameRules"
                  required
                  v-model="company.spoc_name"
                  placeholder="SPOC name" 
                  outlined dense
                ></v-text-field>
              </v-col>
              
              <v-col class="py-0"
                cols="12"
                sm="6"
                md="6"
              >
              <label>SPOC phone number*</label>
                <v-text-field :rules="phoneRules"
                  required
                  maxlength="20"
                  v-model="company.spoc_phone"
                  placeholder="SPOC mobile number"
                  outlined dense
                ></v-text-field>
              </v-col>
              <v-col class="py-0"
                cols="12"
                sm="12"
                md="12"
              >
              <label>SPOC email*</label>
                <v-text-field :rules="emailRules"
                  required
                  v-model="company.spoc_email"
                  placeholder="SPOC email id"
                  outlined dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
          <hr>
          <v-form
            ref="form2"
            v-model="valid2"
            lazy-validation
          >
          <v-container>
            <strong>Plan</strong>
            <v-row class="mt-2">
              <v-col class="py-0"
                cols="12"
                sm="12"
                md="12"
              >
              <label>Title*</label>
                <v-autocomplete
                  :items="titleItem"
                  v-model="plan.title"
                  item-text="name"
                  :rules="nameRules"
                  item-value="value"
                  name='title'
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              </v-row>
              <v-row class="mt-2">
              <v-col class="py-0  pb-2"
                cols="12"
                sm="6"
                md="6"
              >
                <label>Touchpoints</label><br>
                <v-radio-group
                  v-model="plan.milestone_active"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="plan.milestone_active" class="py-0"
                cols="12"
                sm="6"
                md="6"
              >
                <label>Touchpoints limit</label>
                <v-text-field :rules="intRules" required
                  v-model="plan.milestone_limit" maxlength="4"
                  placeholder="Enter touchpoints limit"
                  outlined dense
                ></v-text-field>
              </v-col>
              </v-row>
              <v-row class="mt-2">
              <v-col class="py-0  pb-2"
                cols="12"
                sm="6"
                md="6"
              >
                <label>Ad-hoc</label><br>
                <v-radio-group
                  v-model="plan.adhoc_active"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="py-0"
                cols="12"
                sm="6"
                md="6"
              >
                <label v-if="plan.adhoc_active">Adhoc limit</label>
                <v-text-field v-if="plan.adhoc_active" maxlength="4"
                  v-model="plan.adhoc_limit" :rules="intRules" required
                  placeholder="Enter adhoc limit"
                  outlined dense
                ></v-text-field>
              </v-col>
              </v-row>
              <v-row class="mt-2">
              <v-col class="py-0  pb-2"
                cols="12"
                sm="12"
                md="12"
              >
                <label>Exit</label><br>
                <v-radio-group
                  v-model="plan.exit_active"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              </v-row>
              <v-row class="mt-2">
              <v-col class="py-0  pb-2"
                cols="12"
                sm="12"
                md="12"
              >
                <label>Employee wellbeing</label><br>
                <v-radio-group
                  v-model="plan.employee_wellbeing_active"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              </v-row>

          </v-container>
        </v-form>
          <hr>
          <v-form
            ref="form3"
            v-model="valid3"
            lazy-validation
          >
          <v-container>
            <strong>Company settings</strong>
            <v-row class="mt-2">
              <v-col cols="12" class="domainList">
                <label>Whitelisted email domains</label><br>
                <v-combobox
                  ref="box"
                  :class="company_settings.whitelisted_email_domains && company_settings.whitelisted_email_domains.length ? '' : 'noicon-combo'"
                  v-model="company_settings.whitelisted_email_domains"
                  :items="company_settings.whitelisted_email_domains"
                  multiple
                  chips
                  dense
                  attach
                  outlined
                >
                  <template v-slot:selection="data">
                    <v-chip
                    v-if="data.item.replace(/^\s+|\s+$/gm,'')"
                    color="primary"
                    class="white--text text-capitalize"
                    :input-value="data.selected"
                    outlined
                    >
                    <strong class="text-capitalize">{{ data.item }}</strong>
                    <v-icon
                        small
                        class="ml-2"
                        @click="remove(data.item)"
                    >las la-times-circle</v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <label>Co-branding</label><br>
                <v-radio-group
                  v-model="company.chat_cobranding_enabled"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <label>Resolution summary</label><br>
                <v-radio-group
                  v-model="company_settings.enable_action_summary"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Optional"
                    value="1"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Mandatory"
                    value="2"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    value="0"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <label>Alert word</label><br>
                <v-radio-group
                  v-model="company_settings.chat_alert_word_notification"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <label>Touchpoint edit</label><br>
                <v-radio-group
                  v-model="company.allow_stage_edit"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" >
                <label v-if="company_settings.chat_alert_word_notification">Select alert words</label><br v-if="company_settings.chat_alert_word_notification">
                <v-combobox v-if="company_settings.chat_alert_word_notification"
                  ref="box"
                  eager :rules="nameRules"
                    required
                  v-model="company_settings.chat_alert_words"
                  :items="items"
                  class="combo-input elevation-0 text-capitalize alert-combo"
                  multiple
                  chips
                  attach outlined
                  hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip
                    v-if="data.item.replace(/^\s+|\s+$/gm,'')"
                    color="primary"
                    class="white--text text-capitalize"
                    :input-value="data.selected"
                    outlined
                    >
                    <strong class="text-capitalize">{{ data.item }}</strong>
                    <v-icon
                        small
                        class="ml-2"
                        @click="remove(data.item)"
                    >las la-times-circle</v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <label>Employer Branding</label><br>
                <v-radio-group
                  v-model="company_settings.enable_brand_promotion_feedback"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <label  v-if="company_settings.enable_brand_promotion_feedback">Select employer branding</label>
                <v-autocomplete   v-if="company_settings.enable_brand_promotion_feedback"
                  :items="LinkItem"
                  v-model="company_settings.brand_promotion_feedback_field"
                  item-text="name"
                  placeholder="Select employer branding"
                  outlined dense
                  :rules="company_settings.enable_brand_promotion_feedback ? nameRules : ''"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <label>Disengaged auto assign</label><br>
                <v-radio-group
                  v-model="company_settings.disengaged_auto_assign"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
            
              </v-col>
              <v-col cols="6">
                <label  v-if="company_settings.disengaged_auto_assign">Disengaged auto assign to</label>
                <v-autocomplete  v-if="company_settings.disengaged_auto_assign" 
                  :items="autoAssignItem"
                  v-model="company_settings.disengaged_reference_field"
                  item-text="name"
                  item-value="value"
                  name='disengaged_reference_field'
                  :rules="company_settings.disengaged_auto_assign ? nameRules : ''"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <label>Fence-sitters auto assign</label><br>
                <v-radio-group
                  v-model="company_settings.passively_engaged_auto_assign"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
            
              </v-col>
              <v-col cols="6">
                <label  v-if="company_settings.passively_engaged_auto_assign">Fence-sitters auto assign to</label>
                <v-autocomplete  v-if="company_settings.passively_engaged_auto_assign" 
                  :items="autoAssignItem"
                  v-model="company_settings.passively_reference_field"
                  item-text="name"
                  item-value="value"
                  name='passively_engaged_auto_assign'
                  :rules="company_settings.passively_engaged_auto_assign ? nameRules : ''"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <label>Auto acknowledge</label><br>
                <v-radio-group
                  v-model="company_settings.auto_acknowledge"
                  row
                >
                  <v-radio color="#0ab39c"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#0ab39c"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
            
              </v-col>
              <v-col cols="12">
                <label>Brand color</label><br>
                  <v-row class="w-100 ma-0">
                  <v-col cols="4" class="pl-0">
                    <v-text-field
                    required
                    maxlength="20"
                    v-model="company_settings.chat_color_hex" @focus="showPicker()"
                    @input="updateColors"
                    placeholder="Brand color"
                    outlined dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="displayPicker">
                  <chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker"></chrome-picker>
                  </v-col>
                  <v-col cols="2">
                    <v-btn color="primary" icon>
                      <v-icon style="font-size:12px !important;" @click="chatPreview = true;">fa-regular fa-eye</v-icon>
                    </v-btn>
                    <v-btn v-if="displayPicker" color="primary" icon>
                      <v-icon style="font-size:12px !important;" @click="displayPicker = false;">fa-regular fa-times</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey"
            text
            @click="closeModal()"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!editView" :loading="loading"
            color="#0ab39c"  style="color: white;"
            @click="validate()"
          >
            Save
          </v-btn>
          <v-btn v-if="editView" :loading="loading"
            color="#0ab39c"  style="color: white;"
            @click="validate1()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="chatPreview"
      persistent
      max-width="800px" style="overflow: hidden; background-color: white;"
    >
    <v-card :style="company_settings.chat_color_hex ? `background:linear-gradient(to bottom right, ${company_settings.chat_color_hex}66 0%, ${company_settings.chat_color_hex}88 100%); background-color: white;` : 'background:linear-gradient(to bottom right, #0ab39c66 0%, #0ab39c88 100%); background-color: white;'">
        <div style="max-width:90%; margin-left:5%;">
          <div :style="company_settings.chat_color_hex ? `background: ${company_settings.chat_color_hex};` : 'background: #0ab39c'" style="height:64px; padding: 10px; box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);">
            <v-row style="width:100%; margin: 0;">
              <v-col cols="2" class="pa-0">
                <v-img style="background: white;height:44px;     border-radius: 6px;"
                  contain
                  class="amara_logo"
                  src="../.././assets/amara-black-logo.png"
                ><v-layout
                    slot="placeholder"
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-layout>
                </v-img>
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-spacer></v-spacer>
              <v-tabs class="ChatTabbar" style="background-color: transparent;"
                color="white" centered
              >
                <v-tab href="#normal" style="color: black;font-weight: 600;     height: 44px;">
                  Chat with Amara
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-img
                  contain
                  class="amara_logo" v-if="imageData"
                  :src="imageData" style="background: white; height: 44px; float: right; margin: 0px;     border-radius: 6px;"
                ><v-layout
                    slot="placeholder"
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-layout>
                </v-img>
                <v-img style="background: white; height: 44px; float: right; margin: 0px;     border-radius: 6px;"
                  contain
                  class="amara_logo" v-else
                  :src="company.company_logo"
                ><v-layout
                    slot="placeholder"
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-layout>
                </v-img>
              </v-col>
            </v-row>
          </div>
          <div style="height:500px;overflow: hidden; background-image: url(https://chat.dev.amara.ai/img/background.b68e0aec.jpg);
            background-size: cover;">
          <div style="margin-top:20px">
            <div style="position: relative;">
              <v-row class="w-100 ma-0">
                <v-col cols="2">
                  <div class="v-avatar" style="height: 45px; width: 45px;">
                    <img title="Amara" src="/img/amara-60.fa9f432d.png" alt="Amara" style="background: white; position: absolute; border: 1px solid white; z-index: 999;">
                  </div>
                </v-col>
                <v-col cols="6" style="padding-left: 20px;background: white;border-radius: 6px;border-radius: 20px 10px;position: absolute;left: 45px;top: 27px;">
                  Hi, I am Amara John's new virtual assistant.
                </v-col>
              </v-row>
            </div>
            <div style="position: relative;">
              <v-row class="w-100 ma-0">
                <v-col cols="2">
                  <div class="v-avatar" style="height: 45px; width: 45px;">
                    <img title="Amara" src="/img/amara-60.fa9f432d.png" alt="Amara" style="background: white; position: absolute; border: 1px solid white; z-index: 999;">
                  </div>
                </v-col>
                <v-col cols="8" style="padding-left: 20px;background: white;border-radius: 6px;border-radius: 20px 10px;position: absolute;left: 45px;top: 27px;">
                  I appreciate you making time for our conversation today!
                </v-col>
              </v-row>
            </div>
            <div style="position: relative;">
              <v-row class="w-100 ma-0">
                <v-col cols="2">
                  <div class="v-avatar" style="height: 45px; width: 45px;">
                    <img title="Amara" src="/img/amara-60.fa9f432d.png" alt="Amara" style="background: white; position: absolute; border: 1px solid white; z-index: 999;">
                  </div>
                </v-col>
                <v-col cols="4" style="padding-left: 20px;background: white;border-radius: 6px;border-radius: 20px 10px;position: absolute;left: 45px;top: 27px;">
                  Shall we begin?
                  <button type="button" style="padding: 10px;margin-top: 8px;" class="v-btn theme--light primary"><div class="v-btn__content">Yes, let’s begin</div></button>
                </v-col>
              </v-row>
            </div>
            <div style="position: relative; margin-top: 60px;">
              <v-row  style="    flex-direction: row-reverse; " class="w-100 ma-0">
                <v-col cols="2" style=" text-align: right; z-index: 99;">
                  <div class="v-avatar" style="height: 45px; width: 45px; color: white; font-weight: 600;" :style="company_settings.chat_color_hex ? `background:${company_settings.chat_color_hex}` : 'background:#0ab39c'">
                    AM
                  </div>
                </v-col>
                <v-col cols="3" style="padding-left: 20px;border-radius: 6px;border-radius: 20px 10px;position: absolute;right: 45px;top: 27px;" :style="`float: right; background: ${company_settings.chat_color_hex ? company_settings.chat_color_hex + '33' : '#0ab39c33'}; -webkit-box-flex: 1; -ms-flex: 1 1 auto; flex: 1 1 auto;`">
                  Yes, let’s begin
                </v-col>
              </v-row>
            </div>
          </div>
            <v-btn color="black" style="    position: absolute;
    bottom: 20px;
    right: 50px;
"
            text
            @click="chatPreview = false;"
          >
            Close
          </v-btn>
          </div>
        </div>
        <!-- <v-card-actions>
          <v-btn color="grey"
            text
            @click="chatPreview = false;"
          >
            Close
          </v-btn>
        </v-card-actions> -->
    </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import dayjs from 'dayjs';
import { Chrome } from 'vue-color';
import { mapState } from 'vuex';
  export default {
    components: {
      axios,
      'chrome-picker': Chrome,
      dayjs
    },
    data: () => ({
      copyCompanyData: {},
      copyCompanySetting: {},
      copyCompanyPlan: {},
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      chatPreview: false,
      displayPicker: false,
      auto_acknowledge:false,
      chat_color_hex: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      minDate: new Date().toISOString().substr(0, 10),
      minDate1: new Date().toISOString().substr(0, 10),
      maxDate1: dayjs().add(1, 'month').format(),
      stopChat: 'false',
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      start_date_formated: '',
      end_date_formated: '',
      menu1: false,
      loading: false,
      go_live_date: '',
      joinDateMenu: false,
      date1: '',
      date2: '',
      date3: '',
      titleItem: [{
        name: 'Basic',
        value: 'Basic'
      }, {
        name: 'Advance',
        value: 'Advance'
      }, {
        name: 'Premium',
        value: 'Premium'
      }, {
        name: 'Enterprise',
        value: 'Enterprise'
      }],
      autoAssignItem: [{
        name: 'HR Manager',
        value: 'hr_manager'
      }, {
        name: 'HRBP',
        value: 'hrbp'
      }],
      LinkItem: [{
        name: 'Glassdoor',
        value: 'glassdoor_link'
      }, {
        name: 'JobStreet',
        value: 'jobStreet_link'
      }, {
        name: 'Linkedin',
        value: 'linkedin_link'
      }, {
        name: 'Facebook',
        value: 'facebook_link'
      }],
      dialog: false,
      editView: false,
      imageData: '',
      nameRules: [
        v => !!v || 'This field is required',
      ],
      countryRules: [
        v => !!v || 'This field is required',
        v => !!/\S/.test(v) || 'This field is required',
        v => /^[-a-zA-Z. ]*$/.test(v) || 'Enter a valid country name',
      ],
      addressRules: [
        v => !!v || 'This field is required',
        v => !!/\S/.test(v) || 'This field is required',
      ],
      phoneRules: [
        v => !!v || 'This field is required',
        v => /^\+?[1-9]\d{1,14}$/.test(v) || 'Enter a valid phone number',
      ],
      intRules: [
        v => /^\d+$/.test(v) || 'This field must be an integer',
      ],
      urlRules: [
        v => /^(ftp|http|https):\/\/[^ "]+$/.test(v) || 'URL must be valid (Ex.-https://amara.ai)',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'E-mail must be valid',
      ],
      valid: true,
      valid1: true,
      valid2: true,
      valid3: true,
      industryType: ['Information Technology and Services',
      'Hospital and Health Care',
      'Construction',
      'Education Management',
      'Retail',
      'Financial Services',
      'Accounting',
      'Computer Software',
      'Automotive',
      'Higher Education',
      'Marketing and Advertising',
      'Government Administration',
      'Banking',
      'Health Welness and Fitness',
      'Real Estate',
      'Telecommunications',
      'Oil and Energy',
      'Food and Beverages',
      'Hospitality',
      'Mechanical or Industrial Engineering',
      'Electrical and Electronic Manufacturing',
      'Primary/Secondary Education',
      'Insurance',
      'Internet',
      'Human Resources',
      'Medical Practice',
      'Transportation/Trucking/Railroad',
      'Consumer Services',
      'Management Consulting',
      'Pharmaceuticals',
      'Civil Engineering',
      'Design',
      'Research',
      'Restaurants',
      'Logistics and Supply Chain',
      'Architecture and Planning',
      'Law Practice',
      'Apparel and Fashion',
      'Consumer Goods',
      'Facilities Services',
      'Food Production',
      'Non-profit Organization Management',
      'Entertainment',
      'Machinery',
      'Chemicals',
      'Arts and Crafts',
      'Wholesale',
      'Utilities',
      'Legal Services',
      'Farming',
      'Mining and Metals',
      'Airlines/Aviation',
      'Leisure Travel and Turism',
      'Sporting Goods',
      'Building Materials',
      'Music',
      'Enviromental Services',
      'Professional Training and Coaching',
      'Medical Device',
      'Individual and Family Services',
      'Cosmetics',
      'Mental Health Care',
      'Aviation and Aerospace',
      'Staffing and Recruiting',
      'Industrial Automation',
      'Graphic Design',
      'Security and Investigations',
      'Import and Export',
      'Public Relations and Communications',
      'Textiles',
      'Military',
      'Broadcast Media',
      'Biotechnology',
      'Media Production',
      'Business Supplies and Equipment',
      'Computer Networking',
      'Writing and Editing',
      'Consumer Elecronics',
      'International Trade and Development',
      'Events Services',
      'Photography',
      'Renewables and Envirnoment',
      'Computer Hardware',
      'Civic and Social Organization',
      'Furniture',
      'Defense and Space',
      'Computer and Network Security',
      'Printing',
      'Fine Art',
      'Religious Institutions',
      'Investmend Management',
      'Law Enforcement',
      'Publishing',
      'Information Services',
      'Maritime',
      'Outsourcing/Offshoring',
      'Warehousing',
      'E-learning',
      'Executive Office',
      'Government Relations',
      'Animation',
      'Semiconducs',
      'Supermarkets',
      'Program Development',
      'Public Safety',
      'Plastics',
      'Alternative Medicine',
      'Performing Arts',
      'Online Media',
      'Motion Pictures and Film',
      'Commercial Real Estate',
      'Judiciary',
      'Packaging and Containers',
      'Luxury Goods and Jewelry',
      'Veterinary',
      'Computer Games',
      'Investment Banking',
      'Market Research',
      'International Affairs',
      'Wine and Spirits',
      'Newspapers',
      'Translation and Localisation',
      'Recreational Facilities and Services',
      'Sporting Goods',
      'Paper and Forest Products',
      'Capital Markets',
      'Public Policy',
      'Package/Freight Delivery',
      'Libraries',
      'Wireless',
      'Technology',
      'Sales',
      'Gambling and Casinos',
      'Venture Capital and Private Equity',
      'Glass Ceramics and Concrete',
      'Philanthropy',
      'Ranching',
      'Dairy',
      'Museums and Institutions',
      'Shipbuilding',
      'Think Thanks',
      'Political Organization',
      'Fishery',
      'Fund-Raising',
      'Tobacco',
      'Railroad Manufacture',
      'Alternative Dispute Resolution',
      'Nanotechnology',
      'Legislative Office'],
      items: [
        'abuse',
        'harassment',
        'threat',
        'discrimination',
        'suicide',
        'depressed',
        'lonely',
        'attack',
        'misbehave',
        'Deny',
        'refuse',
        'bias',
        'unfairness',
        'favouritism',
        'racialism',
        'racism',
        'bias',
        'prejudice',
        'bigotry',
        'sexual advances',
        'bribe',
        'blackmail',
        'indescent',
        'Dead',
        'death',
        'anger',
        'hatred',
        'murder',
        'kill',
        'harm',
        'scared',
        'victim',
        'victimised',
        'depression',
        'abusive',
        'angry',
        'helpless',
        'weak',
        'suicide',
        'suicidal',
        'die'
      ],
      company: {
        is_active: true,
        chat_cobranding_enabled: false,
        allow_stage_edit: false,
        company_avatar: '',
        company_avatar_id: '',
      },
      companyOld: {},
      companyAvatarOld: {},
      company_settingsOld: {},
      companyAvatar: [{
        company_avatar: '',
        company_avatar_id: '',
      }],
      company_settings:{
        chat_alert_word_notification:false,
        disengaged_auto_assign:false,
        passively_engaged_auto_assign: false,
        enable_brand_promotion_feedback:false,
        enable_action_summary:"0",
        auto_acknowledge:false

      },
      plan:{
        title:'',
        account_type: 'paid',
        adhoc_active:false,
        exit_active: false,
        adhoc_limit:10,
        milestone_active:true,
        employee_wellbeing_active: false,
        milestone_limit:50
    },
    }),
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      user: state => state.user,
      activeCompany: state => state.activeCompany,
      // activeCompanySettings: state => state.activeCompanySettings,
      // activeCompanyPlan: state => state.activeCompanyPlan
    })
  },
  watch: {
    dialog() {
      if (!this.editView) {
        this.$refs.form.reset()
      }
      if (this.dialog === true) {
        setTimeout(() => {
          this.copyCompanyPlan = JSON.parse(JSON.stringify(this.plan));
          this.copyCompanySetting = JSON.parse(JSON.stringify(this.company_settings));
          this.copyCompanyData = JSON.parse(JSON.stringify(this.company));
        }, 1000);
      }

    },
    // editView () {
    //   if (this.editView) {
    //     const [year, month, day] = this.company.activatedAt.split('-');
    //     this.company.activatedAt = `${day}-${month}-${year}`;
    //   }
    // },
    go_live_date (val) {
      const [year, month, day] = this.go_live_date.split('-');
      this.company.activatedAt = `${day}-${month}-${year}`;
    },
    start_date () {
      this.minDate1 = this.start_date_formated;
      this.maxDate1 = dayjs(this.start_date_formated).add(1, 'month').format();
    },
    start_date_formated () {
      const [year, month, day] = this.start_date_formated.split('-');
      this.company_settings.pause_interaction_start_date = `${day}-${month}-${year}`;
      this.maxDate1 = dayjs(this.start_date).add(1, 'month').format();
    },
    end_date_formated () {
      const [year, month, day] = this.end_date_formated.split('-');
      this.company_settings.pause_interaction_end_date = `${day}-${month}-${year}`;
    },
  },
  methods: {
  documentClick (e) {
    const el = this.$refs.colorpicker;
    const target = e.target;
    if (el !== target && !el.contains(target)) {
      this.hidePicker();
    }
  },
  updateColors (color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        };
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
        const hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3]
        };
      }
    },
  showPicker () {
    this.displayPicker = true;
  },
  hidePicker () {
    this.displayPicker = false;
  },
  togglePicker () {
    this.displayPicker ? this.hidePicker() : this.showPicker();
  },
  updateFromPicker (color) {
    console.log(color);
    this.company_settings.chat_color_hex = color.hex;
    },
  formatDate (date) {
    if (!date) return null;
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  },
  formatDate1 (date) {
    if (!date) return null;
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  },
  parseDate (date) {
    if (!date) return null;
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
  },
  changeAvatar (index) {
    this.company.company_avatar = index;
    this.company.company_avatar_id = index;
    this.$forceUpdate();
    
  },
  changeLogo () {
    this.imageData = '';
    this.company.company_logo = '';
  },
  togglePicker () {
    this.$lodash.each(this.$refs.colorpicker, (v, k) => {
      if (v.displayPicker) {
        v.hidePicker();
      }
    });
    if (this.$refs && this.$refs.colorpicker) {
      this.$refs.colorpicker.displayPicker ? this.$refs.colorpicker.hidePicker() : this.$refs.colorpicker.showPicker();
    }
  },
  closeModal () {
    this.dialog = false;
    if (!this.$route.query.cid) {
    this.$refs.form.reset();
    this.$refs.form1.reset()
    this.$refs.form2.reset()
    this.$refs.form3.reset()
    this.company_settings = {
        chat_alert_word_notification:false,
        disengaged_auto_assign:false,
        passively_engaged_auto_assign: false,
        enable_brand_promotion_feedback:false,
        enable_action_summary:"0",
        auto_acknowledge:false
    }
    this.plan = {
        title:'',
        account_type: 'paid',
        adhoc_active:false,
        exit_active: false,
        employee_wellbeing_active: false,
        adhoc_limit:10,
        milestone_active:true,
        milestone_limit:50
    }
  }
    this.changeLogo();
    if(this.$route.query.cid) {
      this.$parent.getCompanyDetails(this.$route.query.cid);
    }
  },
   validate () {
    this.loading = true;
    this.$refs.form.validate()
    this.$refs.form1.validate()
    this.$refs.form2.validate()
    this.$refs.form3.validate()
    setTimeout(() => {
      if (this.valid && this.valid1 && this.valid2 && this.valid3 && this.company.company_logo) {
        if(this.company_settings.chat_alert_word_notification && this.company_settings.chat_alert_words) {
          this.addCompany();
        } else if (!this.company_settings.chat_alert_word_notification) {
          this.addCompany();
        } else {
          this.loading = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Please add atleast one alert world!'
          });
        }
      } else {
        this.loading = false;
        if (this.company.company_logo) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Fill all the mandatory fields!'
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Please add company logo!'
          });
        }
        
      }
    }, 2000);
  },
  validate1 () {
    this.loading = true;
    this.$refs.form.validate()
    this.$refs.form1.validate()
    this.$refs.form2.validate()
    this.$refs.form3.validate()
    setTimeout(() => {
      if (this.valid && this.valid1 && this.valid2 && this.valid3 && this.company.company_logo) {
        if(this.company_settings.chat_alert_word_notification && this.company_settings.chat_alert_words.length > 0) {
          this.updateCompany();
        } else if (!this.company_settings.chat_alert_word_notification) {
          this.updateCompany();
        } else {
          this.loading = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Please add atleast one alert world!'
          });
        }
      } else {
        this.loading = false;
        if (this.company.company_logo) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Fill all the mandatory fields!'
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Please add company logo!'
          });
        }
      }
    }, 2000);
  },
  previewImage(event) {
    let input = event.target;
    if (input.files && input.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        if (input.files[0].size < 2000000) {
          this.imageData = e.target.result;
          this.company.company_logo = input.files[0];
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Image size cannot be more then 2MB!'
          });
        }
      }
      reader.readAsDataURL(input.files[0]);
    }
  },
  updateCompany () {
    const Profile = JSON.parse(this.activeCompany.company_details);
    const Settings = JSON.parse(this.activeCompany.company_settings);
    const Plan = JSON.parse(this.activeCompany.plan_details);
    delete this.company.tags;
    delete this.company.company_avatar;
    delete this.company.companies_avatar;
    delete this.company.activatedAtNew;
    delete this.company.company_avatar;
    delete this.company.users_status;
    delete Settings.language_configured;
    delete Settings.employee_tags;
    this.company_settings.chat_alert_words = JSON.stringify(this.company_settings.chat_alert_words);
    this.company_settings.whitelisted_email_domains = JSON.stringify(this.company_settings.whitelisted_email_domains);
    const formData = new FormData();
    this.$lodash.each(this.company, (val, key) => {
      if ((Profile[key] !== val) && this.copyCompanyData[key] !== val) {
        if (key === 'is_active' && Profile[key]) {
          formData.append('company__is_active', "False");
        } else if (key === 'company_avatar' && Profile[key]) {
          formData.append('company__company_avatar_id', this.company.company_avatar);
        } else if (key === 'is_active' && !Profile[key]) {
          formData.append('company__is_active', "True");
        } else if (key === 'chat_cobranding_enabled' && Profile[key]) {
          formData.append('company__chat_cobranding_enabled', "False");
        } else if (key === 'chat_cobranding_enabled' && !Profile[key]) {
          formData.append('company__chat_cobranding_enabled', "True");
        } else if (key === 'allow_stage_edit' && Profile[key]) {
          formData.append('company__allow_stage_edit', "False");
        } else if (key === 'allow_stage_edit' && !Profile[key]) {
          formData.append('company__allow_stage_edit', "True");
        } else if (key === 'activatedAt') {
          const [day, month, year] = this.company.activatedAt.split('-');
          this.company.activatedAtNew = `${year}-${month}-${day}`;
          formData.append('company__activatedAt', this.formatDate1(this.company.activatedAtNew));
        } else {
          formData.append(`company__${key}`, val);
        }
      }
    });
    this.$lodash.each(this.company_settings, (val, key) => {
      console.log(val, key);
      if ((Settings[key] !== val) && this.copyCompanySetting[key] !== val) {
        console.log(Settings[key])
        if (key === 'enable_brand_promotion_feedback' && Settings[key]) {
          formData.append('settings__enable_brand_promotion_feedback', "False");
        } else if (key === 'enable_brand_promotion_feedback' && !Settings[key]) {
          formData.append('settings__enable_brand_promotion_feedback', "True");
        } else if (key === 'brand_promotion_feedback_field' && Settings[key] && this.company_settings.brand_promotion_feedback_field) {
          formData.append('settings__brand_promotion_feedback_field', this.company_settings.brand_promotion_feedback_field);
        } else if (key === 'disengaged_auto_assign' && Settings[key]) {
          formData.append('settings__disengaged_auto_assign', "False");
        } else if (key === 'disengaged_auto_assign' && !Settings[key]) {
          formData.append('settings__disengaged_auto_assign', "True");
        } else if (key === 'disengaged_reference_field' && Settings[key] && this.company_settings.disengaged_reference_field) {
          formData.append('settings__disengaged_reference_field', this.company_settings.disengaged_reference_field);
        } else if (key === 'passively_engaged_auto_assign' && Settings[key]) {
          formData.append('settings__passively_engaged_auto_assign', "False");
        } else if (key === 'passively_engaged_auto_assign' && !Settings[key]) {
          formData.append('settings__passively_engaged_auto_assign', "True");
        } else if (key === 'passively_reference_field' && Settings[key] && this.company_settings.passively_reference_field) {
          formData.append('settings__passively_engaged_reference_field', this.company_settings.passively_reference_field);
        } else if (key === 'chat_alert_word_notification' && Settings[key]) {
          formData.append('settings__chat_alert_word_notification', "False");
        } else if (key === 'chat_alert_word_notification' && !Settings[key]) {
          formData.append('settings__chat_alert_word_notification', "True");
        } else if ((key === 'chat_alert_words' && this.company_settings.chat_alert_words)) {
          console.log('fghjkl', this.copyCompanySetting.chat_alert_words, this.company_settings.chat_alert_words);
          if (JSON.stringify(this.copyCompanySetting.chat_alert_words) !== this.company_settings.chat_alert_words) {
            formData.append('settings__chat_alert_words', `${this.company_settings.chat_alert_words}`);
          }
        } else if ((key === 'whitelisted_email_domains' && this.company_settings.whitelisted_email_domains)) {
          if (JSON.stringify(this.copyCompanySetting.whitelisted_email_domains) !== this.company_settings.whitelisted_email_domains) {
            formData.append('settings__whitelisted_email_domains', `${this.company_settings.whitelisted_email_domains}`);
          }
        } else if (key === 'pause_interaction_active' && Settings[key]) {
          formData.append('settings__pause_interaction_active', "False");
        } else if (key === 'pause_interaction_active' && !Settings[key]) {
          formData.append('settings__pause_interaction_active', "True");
        } else if (key === 'auto_acknowledge' && Settings[key]) {
          formData.append('settings__auto_acknowledge', "False");
        } else if (key === 'auto_acknowledge' && !Settings[key]) {
          formData.append('settings__auto_acknowledge', "True");
        } else {
          formData.append(`settings__${key}`, val);
        }
      }
    });
    this.$lodash.each(this.plan, (val, key) => {
      if ((Plan[key] !== val) && this.copyCompanyPlan[key] !== val) {
        if (key === 'adhoc_active' && Plan[key]) {
          formData.append('plan__adhoc_active', "False");
        } else if (key === 'adhoc_active' && !Plan[key]) {
          formData.append('plan__adhoc_active', "True");
        } else if (key === 'exit_active' && Plan[key]) {
          formData.append('plan__exit_active', "False");
        } else if (key === 'exit_active' && !Plan[key]) {
          formData.append('plan__exit_active', "True");
        } else if (key === 'employee_wellbeing_active' && Plan[key]) {
          formData.append('plan__employee_wellbeing_active', "False");
        } else if (key === 'employee_wellbeing_active' && !Plan[key]) {
          formData.append('plan__employee_wellbeing_active', "True");
        } else if (key === 'milestone_active' && Plan[key]) {
          formData.append('plan__milestone_active', "False");
        } else if (key === 'milestone_active' && !Plan[key]) {
          formData.append('plan__milestone_active', "True");
        } else if (key === 'title') {
          formData.append('plan__title', this.company.company_name +' - '+val);
        } else {
          formData.append(`plan__${key}`, val);
        }
      }
    });
    this.company_settings.chat_alert_words = JSON.parse(this.company_settings.chat_alert_words);
    this.company_settings.whitelisted_email_domains = JSON.parse(this.company_settings.whitelisted_email_domains);
    if (this.imageData.length > 0) {
      formData.append('company__company_logo', this.company.company_logo);
    }
    
    axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
    axios.patch(`${process.env.VUE_APP_API_URL}company/update/${this.$route.query.cid}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then((response) => {
      if (response && response.data) {
        this.loading = false;
        this.dialog = false;
        this.$parent.getCompanyDetails(this.$route.query.cid);
        this.$store.dispatch('updateSnackbar', {
          color: 'success',
          show: true,
          text: 'Company updated successfully!'
        });
        this.$router.push(`/company-details?cid=${this.$route.query.cid}`);
      }
    }).catch((err) => {
      this.loading = false;
      if (err.response.status === 429) {
        this.$store.dispatch('updateSnackbar', {
          color: 'error',
          show: true,
          text: err.response.data.error
        });
      } else if (err.response.status === 400) {
        let mesage = err.response.data.message.split(' - ');
        const correctedJsonString = mesage[1].replace(/'/g, '"');
        const jsonObject = JSON.parse(correctedJsonString);
        let errorField = '';
        let errorInfo = '';

        for (const field in jsonObject) {
          if (jsonObject.hasOwnProperty(field)) {
            const firstError = jsonObject[field][0];
            if (firstError) {
              errorField = field;
              errorInfo = firstError;
            }
          }
        }
        setTimeout(() => {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: `Error field: ${errorField}, Error: ${errorInfo}`
          });
        }, 100);
      } else {
        this.$store.dispatch('updateSnackbar', {
          color: 'error',
          show: true,
          text: 'Unable to fetch details, Please try again later!'
        });
      }
    });
  },
  getAvatar () {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}company/avatar`).then((response) => {
          if (response && response.data) {
            this.company.companies_avatar = response.data;
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      addCompany () {
        this.company.createdBy = this.user.email;
        if (this.company_settings.chat_alert_words) {
          this.company_settings.chat_alert_words = JSON.stringify(this.company_settings.chat_alert_words);
        }
        const formData = new FormData();
        if (this.imageData.length > 0) {
          formData.append('company__company_logo', this.company.company_logo);
        }
        if (this.company.company_name) {
          formData.append('company__company_name', this.company.company_name);
        }
        if (this.company.company_avatar_id) {
          formData.append('company__company_avatar', this.company.company_avatar_id);
        }
        if (this.company.company_address) {
          formData.append('company__company_address', this.company.company_address);
        }
        if (this.company.activatedAt) {
          const [day, month, year] = this.company.activatedAt.split('-');
          this.company.activatedAtNew = `${year}-${month}-${day}`;
          formData.append('company__activatedAt', this.formatDate1(this.company.activatedAtNew));
        }
        if (this.company.user_booked) {
          formData.append('company__user_booked', this.company.user_booked);
        }
        if (this.company.total_arr) {
          formData.append('company__total_arr', this.company.total_arr);
        }
        formData.append('company__brand_name', this.company.brand_name);
        if (this.company.company_type) {
          formData.append('company__company_type', this.company.company_type);
        }
        if (this.company.facebook_link) {
          formData.append('company__facebook_link', this.company.facebook_link);
        }
        if (this.company.linkedin_link) {
          formData.append('company__linkedin_link', this.company.linkedin_link);
        }
        if (this.company.glassdoor_link) {
          formData.append('company__glassdoor_link', this.company.glassdoor_link);
        }
        if (this.company.jobStreet_link) {
          formData.append('company__jobStreet_link', this.company.jobStreet_link);
        }
        formData.append('company__spoc_name', this.company.spoc_name);
        if (this.company.chat_cobranding_enabled) {
          formData.append('company__chat_cobranding_enabled', "True");
        } else {
          formData.append('company__chat_cobranding_enabled', "False");
        }
        if (this.company.allow_stage_edit) {
          formData.append('company__allow_stage_edit', "True");
        } else {
          formData.append('company__allow_stage_edit', "False");
        }
        if (this.company.is_active) {
          formData.append('company__is_active', "True");
        } else {
          formData.append('company__is_active', "False");
        }
        formData.append('company__spoc_email', this.company.spoc_email);
        formData.append('company__spoc_phone', this.company.spoc_phone);
        formData.append('company__country', this.company.country);
        if (this.company.employee_strength) {
          formData.append('company__employee_strength', this.company.employee_strength);
        }
        formData.append('plan__title', this.plan.title);
        formData.append('plan__account_type', this.plan.account_type);
        formData.append('company__country', this.company.country);
        formData.append('company__createdBy', this.company.createdBy);
        if (this.company_settings.chat_alert_word_notification) {
          formData.append('settings__chat_alert_word_notification', "True");
          formData.append('settings__chat_alert_words', this.company_settings.chat_alert_words);
        } else {
          formData.append('settings__chat_alert_word_notification', "False");
        }
        if (this.plan.title) {
          formData.append('plan__title', this.plan.title);
        }
        if (this.plan.adhoc_active) {
          formData.append('plan__adhoc_active', "True");
        } else {
          formData.append('plan__adhoc_active', "False");
        }
        if (this.plan.adhoc_limit) {
          formData.append('plan__adhoc_limit', this.plan.adhoc_limit);
        }
        if (this.plan.exit_active) {
          formData.append('plan__exit_active', "True");
        } else {
          formData.append('plan__exit_active', "False");
        }
        if (this.plan.employee_wellbeing_active) {
          formData.append('plan__employee_wellbeing_active', "True");
        } else {
          formData.append('plan__employee_wellbeing_active', "False");
        }
        if (this.plan.milestone_active) {
          formData.append('plan__milestone_active', "True");
        } else {
          formData.append('plan__milestone_active', "False");
        }
        if (this.plan.milestone_limit) {
          formData.append('plan__milestone_limit', this.plan.milestone_limit);
        }
        if (this.company_settings.enable_brand_promotion_feedback) {
          formData.append('settings__enable_brand_promotion_feedback', "True");
          formData.append('settings__brand_promotion_feedback_field', this.company_settings.brand_promotion_feedback_field);
        } else {
          formData.append('settings__enable_brand_promotion_feedback', "False");
        }
        if (this.company_settings.auto_acknowledge) {
          formData.append('settings__auto_acknowledge', "True");
        } else {
          formData.append('settings__auto_acknowledge', "False");
        }
        if (this.company_settings.disengaged_auto_assign) {
          formData.append('settings__disengaged_auto_assign', "True");
          formData.append('settings__disengaged_reference_field', this.company_settings.disengaged_reference_field);
        } else {
          formData.append('settings__disengaged_auto_assign', "False");
        }
        if (this.company_settings.passively_engaged_auto_assign) {
          formData.append('settings__passively_engaged_auto_assign', "True");
          formData.append('settings__passively_engaged_reference_field', this.company_settings.passively_reference_field);
        } else {
          formData.append('settings__passively_engaged_auto_assign', "False");
        }
        formData.append('settings__enable_action_summary', this.company_settings.enable_action_summary);
        if (this.company_settings.chat_alert_words) {
          this.company_settings.chat_alert_words = JSON.parse(this.company_settings.chat_alert_words);
        }
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.post(`${process.env.VUE_APP_API_URL}company/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
        }).then((response) => {
          if (response && response.data) {
            this.loading = false;
            this.dialog = false;
            this.$parent.getCompanyDetails(this.$route.query.cid);
            this.dashboardData = response.data;
            this.$store.dispatch('updateSnackbar', {
              color: 'success',
              show: true,
              text: 'Company added successfully!'
            });
            setTimeout(() => {
              this.company_settings = {
                chat_alert_word_notification:false,
                disengaged_auto_assign:false,
                passively_engaged_auto_assign:false,
                enable_brand_promotion_feedback:false,
                enable_action_summary:"0",
                auto_acknowledge:false
              }
              this.plan = {
                  title:'',
                  account_type: 'paid',
                  adhoc_active:false,
                  exit_active: false,
                  employee_wellbeing_active: false,
                  adhoc_limit:10,
                  milestone_active:true,
                  milestone_limit:50
              }
              this.changeLogo();
            }, 100);
          }
        }).catch((err) => {
          this.loading = false;
          if (err.response.status === 400) {
            if (err.response.data.title) {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: `Company name : ${err.response.data.title[0]}`
              });
            }
            if (err.response.data.employee_strength) {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: `Employee strength : ${err.response.data.employee_strength[0]}`
              });
            }
            if (err.response.data.spoc_email) {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: `SPOC email : ${err.response.data.spoc_email[0]}`
              });
            }
            
            if (err.response.data.spoc_phone) {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: `SPOC phone : ${err.response.data.spoc_phone[0]}`
              });
            }
            if (!err.response.data.spoc_email && !err.response.data.employee_strength) {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: err.response.data
              });
            }
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
    },
    mounted: {
      opendialog () {
        this.dialog = true;
      },
    }
  }
</script>

<style lang="scss">
.totalArrField {
  .v-input__icon {
    font-size: 18px !important;
  }
}
.addCompanyPage {
  .amara_logo {
  width:120px;
}
.v-tabs-bar {
  background-color: transparent !important;
  a {
    color: white !important;
    border: 2px solid;
    border-radius: 6px 6px 0 0;
  }
}
}
.noicon-combo .v-select__slot .v-input__icon {
  display: none !important;
}
.domainList {
  .v-chip__content {
    strong {
      text-transform: initial !important;
    }
  }
}
input[type=file]:before {
  content: 'Drag and drop or Click \A to update company logo.';
  white-space: pre-wrap;
  vertical-align: middle;
  text-align: center;
  font-size: 1em;
  line-height: 1.5;
  display: inline-block;
  background: #f1f1f1;
  padding: 17% 34px;
  font-family: Helvetica, Arial, sans-serif;
}

input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
}
.avatarBox {
  border: 1px solid #eaeaea;
  border-radius:6px;
}
.editAvatarBox {
  pointer-events: initial;
}
.editAvatarBox:hover {
  border: 2px solid #0ab39c;
  cursor: pointer;
}
.ChatTabbar {
  .v-tabs-bar {
    background-color: transparent !important;
  }
}
.ChatTabbar {
  .v-tabs-slider-wrapper {
    height: 0px !important;
  }
  .v-tab--active {
    background: white !important;
    border-radius: 6px;
    .v-icon {
      color: white;
    }
}
.v-tabs__div:has(.v-tab--active)::before {
    content: "";
    position: absolute;
    border: 6px solid white;
    bottom: -2px;
    left: 40%;
    transform: rotate(45deg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.addBrandColor .v-tab--active {
  opacity: 1 !important;
  color: black !important;
}
.addBrandColor .v-tab--active .v-icon {
  color: black !important;
} 
.v-tab--active::before {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  transition-property: background-color,
  border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 100ms;
}
}


</style>